//Imports
import FloatingEdge from './FloatingEdge';
import FloatingConnectionLine from './FloatingConnectionLine';
import {
  FormControl,
  FormLabel,
  Switch,
  useDisclosure,
} from '@chakra-ui/react'
import ReactFlow, { Edge, Node, ReactFlowProvider } from "react-flow-renderer";
import { GetEdgeApiResponse, GetNodeApiResponse, GetSys2ScopeApiResponse, Sys2Node, useGetDiagramQuery, useGetEdgeQuery, useGetNodeQuery, useGetScopeQuery, useGetSys2ScopeQuery, usePatchEdgeMutation, usePatchNodeMutation, usePatchSys2ScopeMutation } from "./app/services/profilerApi";
import NodeDialog from "./NodeDialog";
import { useCallback, useMemo, useState } from 'react';

//Stateless Variables
const animated = true;
const edgetype = "floating";
const edgeTypes = { floating: FloatingEdge }; 
const styles : any= {
  "unconfigured": { backgroundColor: "#d4d4d4", color: "black" } as React.CSSProperties, //grey
  "todo": { backgroundColor: "#d4d4d4", color: "black", borderColor: "red" } as React.CSSProperties, //grey with red-outline
  "wip":  { backgroundColor: "#0247D2", color: "white", fontWeight: "bold" } as React.CSSProperties, //blue
  "active": { backgroundColor: "#00B050", color: "white", fontWeight: "bold" } as React.CSSProperties //green
}

//Helper Functions
export function sorter(a: any, b: any) {
  if(a.name > b.name) {
      return 1;
  }else if(a.name < b.name) {
      return -1;
  }else {
      return 0;
  }
}
export function computeKey(a: any, b: any) {
  var tmp:string[] = []
  if (a) {
    tmp.push(a)
  }
  if (b) {
    tmp.push(b)
  }
  return tmp.join(',')
}
export function getScopeChoices(key: string, store: any) {
  if(!store[key]) {
    console.log("getScopeChoices(): No Entry in store for key="+key)
    store[key] = []
  }
  return store[key]
}

function processResponseData(nodesData: GetNodeApiResponse, edgesData: GetEdgeApiResponse): {
  initialNodes: Node[];
  initialEdges: Edge[];
  nodeDataById: any;
  systemChoicesByNode: {[index: string]:any};
  subsystemChoicesByNode: {[index: string]:any};
} {
if(!nodesData || !edgesData) {
  return {initialNodes: [], initialEdges: [], nodeDataById: {}, systemChoicesByNode: {}, subsystemChoicesByNode: {}
    }
}else{
  console.log("BIG EXPENSIVE DSGFlow COMPONENT PROCESS nodesData edgesData ")
  const nodeDataById: any = {};
  var systemChoicesByNode: {[index: string]:any}  = {}
  var subsystemChoicesByNode: {[index: string]:any}  = {}
  var nameOverridesByNodeId: {[index: string]:any}  = {}
  nodesData.forEach((nodeData) => {
    nodeDataById[nodeData.id] = nodeData
    nameOverridesByNodeId[nodeData.id] = nodeData.name
    systemChoicesByNode[nodeData.id] = []
    subsystemChoicesByNode[nodeData.id] = []
    var test : any[] = (nodeData as any).sys2node
    test.forEach((res, idx) => {
        if(res.context === 'System') {
          systemChoicesByNode[nodeData.id].push({name: res.sys.name, id: res.sys.id})
          if (res.sys.id === nodeData.selected_system_id) {
            nameOverridesByNodeId[nodeData.id] = res.sys.name
          }
        }else{
          subsystemChoicesByNode[nodeData.id].push({name: res.sys.name, id: res.sys.id, dependency: res.dependency_sys_id})

        }
    })
  })

  const initialNodes: Node[] = nodesData.map((res) => (
    { id: res.id.toString(), data: { label: nameOverridesByNodeId[res.id] }, position: { x: res.xpos, y: res.ypos }, style: styles[res.status as string] } as Node
  )) 
  const initialEdges: Edge[] = edgesData.map((res, idx) => (
    { id: idx.toString(), source: res.from_id?.toString(), target: res.to_id?.toString(), animated: animated, type: edgetype } as Edge
  ))

  return { initialNodes: initialNodes, initialEdges: initialEdges, nodeDataById: nodeDataById, systemChoicesByNode: systemChoicesByNode, subsystemChoicesByNode: subsystemChoicesByNode, 
        }
  }
}

//React Component
const DSGFlow = () => {
  //State
  const [diagram, setDiagram] = useState("1")
  const [nodeId, setNodeId] = useState(0);
  const [nodeName, setNodeName] = useState("")
  const [nodeStatus, setNodeStatus] = useState("")
  const [selectedSystemId, setSelectedSystemId] = useState(0)
  const [selectedSubsystemId, setSelectedSubsystemId] = useState(0)
  const [systemChoices, setSystemChoices] = useState([])
  const [subsystemChoices, setSubsystemChoices] = useState([])
  const [scopeChoices, setScopeChoices] = useState<{ name: any; scope_id: any; sys_id: any; selected: any; }[] | undefined>([])

  //Queries
  const { data: diagramsData, error: diagramsError, isLoading: diagramsIsLoading, isFetching: diagramsIsFetching } = useGetDiagramQuery({})
  const { data: nodesData, error: nodesError, isLoading: nodesIsLoading, isFetching: nodesIsFetching } = useGetNodeQuery(
    { diagramId: 'eq.'+diagram,
      select: 'id,diagram_id,name,xpos,ypos,status,selected_system_id,selected_subsystem_id,sys2node(sys_id,node_id,context,dependency_sys_id,sys!sys2node_sys_id_fkey(id,name,sys2scope(sys_id,scope_id,selected,scope(name))))'})
  const { data: edgesData, error: edgesError, isLoading: edgesIsLoading, isFetching: edgesIsFetching } = useGetEdgeQuery({})
  const { data: scopesData, error: scopesError, isLoading: scopesIsLoading, isFetching: scopesIsFetching, refetch: refetchScopesData } = useGetSys2ScopeQuery(
    { sysId: 'in.('+computeKey(selectedSystemId, selectedSubsystemId)+')',
        select: 'sys_id,scope_id,selected,sys!inner(name),scope!inner(name)',
        order: 'scope(name)',
    }
  )
  //Todo: RTK Query updates via Websockets: PGWS_PORT=3002 PGWS_DB_URI="postgres://dsg:dsgdb4e@localhost:5432/dsg" PGWS_JWT_SECRET="digitalsolutiongroupdatabaseforenterprise" ~/.local/bin/postgres-websockets
  
  //Mutations
  const [
    updateNode, // This is the mutation trigger
    { isLoading: isNodeUpdating }, // This is the destructured mutation result
  ] = usePatchNodeMutation()
  const [
    updateSys2Scope, // This is the mutation trigger
    { isLoading: isSys2ScopeUpdating }, // This is the destructured mutation result
  ] = usePatchSys2ScopeMutation()
  const [
    updateEdge, // This is the mutation trigger
    { isLoading: isEdgeUpdating }, // This is the destructured mutation result
  ] = usePatchEdgeMutation()

  //Memoized Work
  const {initialNodes, initialEdges, nodeDataById, systemChoicesByNode, subsystemChoicesByNode} = useMemo(() => 
  processResponseData(nodesData, edgesData), [nodesData, edgesData]); 
  const scopes = useMemo(() => scopesData?.map((s: any) => (
      {name: s.scope.name, scope_id: s.scope_id, sys_id: s.sys_id, selected: s.selected}
      )).sort(sorter),[scopesData, nodeId, selectedSystemId, selectedSubsystemId]) 
  
  
  //Disclosures
  const { isOpen: isNodeOpen , onOpen: onNodeOpen, onClose: onNodeClose } = useDisclosure()

  //Handlers
  const onNodeClick = 
    (a: any) => {
      var tmp: number = parseInt(a.target.dataset.id);
      setNodeId(tmp)
      console.log("onNodeClick: tmp="+tmp+", nodeId="+nodeId+", nodeDataById="+nodeDataById)
      const nodeData = nodeDataById[tmp];
      console.log(JSON.stringify(nodeData));
      setNodeName(nodeData.name!)
      setNodeStatus(nodeData.status!)
      setSelectedSystemId(nodeData.selected_system_id!)
      setSelectedSubsystemId(nodeData.selected_subsystem_id!)
      setSystemChoices(systemChoicesByNode[nodeData.id].sort(sorter) as any)
      setSubsystemChoices(subsystemChoicesByNode[nodeData.id].sort(sorter) as any)
      // setScopeChoices(scopes)
      onNodeOpen()
    }
  
   const handleDone = () => {
    console.log("handleDone")
    //save changes to node(s), sys2scopes, and edges
    const orig = nodeDataById[nodeId]
    const clone = (({ sys2node,  ...o }) => o)(orig) // remove b and c
    clone.selected_system_id = (selectedSystemId === 0 ? null : selectedSystemId)
    clone.selected_subsystem_id = (selectedSubsystemId === 0 ? null : selectedSubsystemId)
    clone.status = nodeStatus
    console.log('clone='+JSON.stringify(clone)+"\norig="+JSON.stringify(orig))
    if(!(clone.selected_system_id === orig.selected_system_id && 
         clone.selected_subsystem_id === orig.selected_subsystem_id && 
         clone.status === orig.status) ) {
      console.log("Saving Node Changes")    
      updateNode({
        id: 'eq.'+nodeId.toString(), 
        node: clone
      })
      //set nodes = {...nodes, label=}
    }else{
      console.log("No Node Changes to Save")
    }
    scopeChoices?.map((s: any, i) => {
      //Todo: save only when changed
      console.log("updateSys2Scope "+JSON.stringify(s))
      updateSys2Scope({
        scopeId: 'eq.'+s.scope_id,
        sysId: 'eq.'+s.sys_id,
        sys2Scope: {selected: s.selected,
                    scope_id:  s.scope_id,
                    sys_id: s.sys_id
                   }
      })
    })
    onNodeClose()
  }

  //Render
  if (nodesIsLoading || edgesIsLoading) return <div>Loading...</div>
  if (nodesError || edgesError) return <div>Error.</div>
  if(nodesData && edgesData && scopesData) {
    return (
      <ReactFlowProvider>
        <div className="flow" style={{ position: 'absolute', width: "100%", height: "100%" }}>
          <FormControl display='flex' alignItems='center'>
            <Switch size='lg' id='diagramStatus' onChange={(e: any) => {
              console.log(e)
              if(e.target.checked) {
                setDiagram('2')
              }else{
                setDiagram('1')
              }
            }}/>
            <FormLabel htmlFor='diagramStatus' mb='0' className='diagramStatus'>
              {{'1' : 'As-Is', '2' : 'To-Be'}[diagram]} {/* Todo: use diagramData */}
            </FormLabel>
          </FormControl>
        </div>
        <div className="flow" style={{ position: 'absolute', width: "85%", height: "100%" }}>
          <ReactFlow 
            nodes={initialNodes} edges={initialEdges} 
            fitView 
            zoomOnPinch={false} zoomOnScroll={false} zoomOnDoubleClick={false}
            panOnDrag={false}
            nodesDraggable={false}
            onNodeClick={ onNodeClick }
            edgeTypes={edgeTypes}
            connectionLineComponent={FloatingConnectionLine}
          >
            <NodeDialog 
              nodeId={nodeId}  nodeDataById={nodeDataById}
              isNodeOpen={isNodeOpen} onNodeClose={onNodeClose} handleDone={handleDone}
              nodeName={nodeName} setNodeName={setNodeName} selectedSystemId={selectedSystemId} setSelectedSystemId={setSelectedSystemId} 
              selectedSubsystemId={selectedSubsystemId} setSelectedSubsystemId={setSelectedSubsystemId} 
              scopeChoices={scopeChoices} setScopeChoices={setScopeChoices}
              scopes={scopes} refetchScopesData={refetchScopesData}
              systemChoices={systemChoices} setSystemChoices={setSystemChoices} 
              subsystemChoices={subsystemChoices} setSubsystemChoices={setSubsystemChoices}
              nodeStatus={nodeStatus} setNodeStatus={setNodeStatus}
            />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
      )
  } else {
    return <div>No Data.</div>
  }
}

export default DSGFlow
