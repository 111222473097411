import * as React from "react";
import { SVGProps } from "react";

const SvgCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    preserveAspectRatio="none"
    viewBox="-0.5 -0.5 121 81"
    {...props}
  >
    <path
      d="M30 20C6 20 0 40 19.2 44c-19.2 8.8 2.4 28 18 20C48 80 84 80 96 64c24 0 24-16 9-24 15-16-9-32-30-24C60 4 36 4 30 20Z"
      fill="#FFF"
      stroke="#000"
      strokeWidth={0.2}
      strokeMiterlimit={10}
      pointerEvents="all"
    />
  </svg>
);

export default SvgCloud;
