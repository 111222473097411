import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../features/auth/authSlice'
import { selectCurrentToken } from '../features/auth/authSlice'

export const useAuth = () => {
  // const user = useSelector(selectCurrentUser)
  const token = useSelector(selectCurrentToken)

  //return useMemo(() => ({ user }), [user])
  return useMemo(() => ({ token }), [token])
}
