import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    get: build.query<GetApiResponse, GetApiArg>({
      query: () => ({ url: `/` }),
    }),
    getMsg: build.query<GetMsgApiResponse, GetMsgApiArg>({
      query: (queryArg) => ({
        url: `/msg`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          id: queryArg.id,
          received: queryArg.received,
          status_id: queryArg.statusId,
          customer_id: queryArg.customerId,
          type_id: queryArg.typeId,
          data: queryArg.data,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postMsg: build.mutation<PostMsgApiResponse, PostMsgApiArg>({
      query: (queryArg) => ({
        url: `/msg`,
        method: "POST",
        body: queryArg.msg,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteMsg: build.mutation<DeleteMsgApiResponse, DeleteMsgApiArg>({
      query: (queryArg) => ({
        url: `/msg`,
        method: "DELETE",
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          received: queryArg.received,
          status_id: queryArg.statusId,
          customer_id: queryArg.customerId,
          type_id: queryArg.typeId,
          data: queryArg.data,
        },
      }),
    }),
    patchMsg: build.mutation<PatchMsgApiResponse, PatchMsgApiArg>({
      query: (queryArg) => ({
        url: `/msg`,
        method: "PATCH",
        body: queryArg.msg,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          received: queryArg.received,
          status_id: queryArg.statusId,
          customer_id: queryArg.customerId,
          type_id: queryArg.typeId,
          data: queryArg.data,
        },
      }),
    }),
    getSys2Node: build.query<GetSys2NodeApiResponse, GetSys2NodeApiArg>({
      query: (queryArg) => ({
        url: `/sys2node`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          sys_id: queryArg.sysId,
          node_id: queryArg.nodeId,
          context: queryArg.context,
          dependency_sys_id: queryArg.dependencySysId,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postSys2Node: build.mutation<PostSys2NodeApiResponse, PostSys2NodeApiArg>({
      query: (queryArg) => ({
        url: `/sys2node`,
        method: "POST",
        body: queryArg.sys2Node,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteSys2Node: build.mutation<
      DeleteSys2NodeApiResponse,
      DeleteSys2NodeApiArg
    >({
      query: (queryArg) => ({
        url: `/sys2node`,
        method: "DELETE",
        headers: { Prefer: queryArg.prefer },
        params: {
          sys_id: queryArg.sysId,
          node_id: queryArg.nodeId,
          context: queryArg.context,
          dependency_sys_id: queryArg.dependencySysId,
        },
      }),
    }),
    patchSys2Node: build.mutation<
      PatchSys2NodeApiResponse,
      PatchSys2NodeApiArg
    >({
      query: (queryArg) => ({
        url: `/sys2node`,
        method: "PATCH",
        body: queryArg.sys2Node,
        headers: { Prefer: queryArg.prefer },
        params: {
          sys_id: queryArg.sysId,
          node_id: queryArg.nodeId,
          context: queryArg.context,
          dependency_sys_id: queryArg.dependencySysId,
        },
      }),
    }),
    getSys2Scope2Node: build.query<
      GetSys2Scope2NodeApiResponse,
      GetSys2Scope2NodeApiArg
    >({
      query: (queryArg) => ({
        url: `/sys2scope2node`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          sys_id: queryArg.sysId,
          scope_id: queryArg.scopeId,
          node_id: queryArg.nodeId,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postSys2Scope2Node: build.mutation<
      PostSys2Scope2NodeApiResponse,
      PostSys2Scope2NodeApiArg
    >({
      query: (queryArg) => ({
        url: `/sys2scope2node`,
        method: "POST",
        body: queryArg.sys2Scope2Node,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteSys2Scope2Node: build.mutation<
      DeleteSys2Scope2NodeApiResponse,
      DeleteSys2Scope2NodeApiArg
    >({
      query: (queryArg) => ({
        url: `/sys2scope2node`,
        method: "DELETE",
        headers: { Prefer: queryArg.prefer },
        params: {
          sys_id: queryArg.sysId,
          scope_id: queryArg.scopeId,
          node_id: queryArg.nodeId,
        },
      }),
    }),
    patchSys2Scope2Node: build.mutation<
      PatchSys2Scope2NodeApiResponse,
      PatchSys2Scope2NodeApiArg
    >({
      query: (queryArg) => ({
        url: `/sys2scope2node`,
        method: "PATCH",
        body: queryArg.sys2Scope2Node,
        headers: { Prefer: queryArg.prefer },
        params: {
          sys_id: queryArg.sysId,
          scope_id: queryArg.scopeId,
          node_id: queryArg.nodeId,
        },
      }),
    }),
    getTools: build.query<GetToolsApiResponse, GetToolsApiArg>({
      query: (queryArg) => ({
        url: `/tools`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          active: queryArg.active,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postTools: build.mutation<PostToolsApiResponse, PostToolsApiArg>({
      query: (queryArg) => ({
        url: `/tools`,
        method: "POST",
        body: queryArg.tools,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteTools: build.mutation<DeleteToolsApiResponse, DeleteToolsApiArg>({
      query: (queryArg) => ({
        url: `/tools`,
        method: "DELETE",
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          active: queryArg.active,
        },
      }),
    }),
    patchTools: build.mutation<PatchToolsApiResponse, PatchToolsApiArg>({
      query: (queryArg) => ({
        url: `/tools`,
        method: "PATCH",
        body: queryArg.tools,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          active: queryArg.active,
        },
      }),
    }),
    getSys2Scope: build.query<GetSys2ScopeApiResponse, GetSys2ScopeApiArg>({
      query: (queryArg) => ({
        url: `/sys2scope`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          sys_id: queryArg.sysId,
          scope_id: queryArg.scopeId,
          selected: queryArg.selected,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
      providesTags: ["Sys2Scope"],
    }),
    postSys2Scope: build.mutation<
      PostSys2ScopeApiResponse,
      PostSys2ScopeApiArg
    >({
      query: (queryArg) => ({
        url: `/sys2scope`,
        method: "POST",
        body: queryArg.sys2Scope,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteSys2Scope: build.mutation<
      DeleteSys2ScopeApiResponse,
      DeleteSys2ScopeApiArg
    >({
      query: (queryArg) => ({
        url: `/sys2scope`,
        method: "DELETE",
        headers: { Prefer: queryArg.prefer },
        params: {
          sys_id: queryArg.sysId,
          scope_id: queryArg.scopeId,
          selected: queryArg.selected,
        },
      }),
    }),
    patchSys2Scope: build.mutation<
      PatchSys2ScopeApiResponse,
      PatchSys2ScopeApiArg
    >({
      query: (queryArg) => ({
        url: `/sys2scope`,
        method: "PATCH",
        body: queryArg.sys2Scope,
        headers: { Prefer: queryArg.prefer },
        params: {
          sys_id: queryArg.sysId,
          scope_id: queryArg.scopeId,
          selected: queryArg.selected,
        },
      }),
      invalidatesTags: ["Node", "Sys2Scope"]
    }),
    getEdge: build.query<GetEdgeApiResponse, GetEdgeApiArg>({
      query: (queryArg) => ({
        url: `/edge`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          from_id: queryArg.fromId,
          to_id: queryArg.toId,
          monitored: queryArg.monitored,
          status: queryArg.status,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postEdge: build.mutation<PostEdgeApiResponse, PostEdgeApiArg>({
      query: (queryArg) => ({
        url: `/edge`,
        method: "POST",
        body: queryArg.edge,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteEdge: build.mutation<DeleteEdgeApiResponse, DeleteEdgeApiArg>({
      query: (queryArg) => ({
        url: `/edge`,
        method: "DELETE",
        headers: { Prefer: queryArg.prefer },
        params: {
          from_id: queryArg.fromId,
          to_id: queryArg.toId,
          monitored: queryArg.monitored,
          status: queryArg.status,
        },
      }),
    }),
    patchEdge: build.mutation<PatchEdgeApiResponse, PatchEdgeApiArg>({
      query: (queryArg) => ({
        url: `/edge`,
        method: "PATCH",
        body: queryArg.edge,
        headers: { Prefer: queryArg.prefer },
        params: {
          from_id: queryArg.fromId,
          to_id: queryArg.toId,
          monitored: queryArg.monitored,
          status: queryArg.status,
        },
      }),
    }),
    getSys: build.query<GetSysApiResponse, GetSysApiArg>({
      query: (queryArg) => ({
        url: `/sys`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postSys: build.mutation<PostSysApiResponse, PostSysApiArg>({
      query: (queryArg) => ({
        url: `/sys`,
        method: "POST",
        body: queryArg.sys,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteSys: build.mutation<DeleteSysApiResponse, DeleteSysApiArg>({
      query: (queryArg) => ({
        url: `/sys`,
        method: "DELETE",
        headers: { Prefer: queryArg.prefer },
        params: { id: queryArg.id, name: queryArg.name },
      }),
    }),
    patchSys: build.mutation<PatchSysApiResponse, PatchSysApiArg>({
      query: (queryArg) => ({
        url: `/sys`,
        method: "PATCH",
        body: queryArg.sys,
        headers: { Prefer: queryArg.prefer },
        params: { id: queryArg.id, name: queryArg.name },
      }),
    }),
    getDiagram: build.query<GetDiagramApiResponse, GetDiagramApiArg>({
      query: (queryArg) => ({
        url: `/diagram`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          created_at: queryArg.createdAt,
          updated_at: queryArg.updatedAt,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postDiagram: build.mutation<PostDiagramApiResponse, PostDiagramApiArg>({
      query: (queryArg) => ({
        url: `/diagram`,
        method: "POST",
        body: queryArg.diagram,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteDiagram: build.mutation<
      DeleteDiagramApiResponse,
      DeleteDiagramApiArg
    >({
      query: (queryArg) => ({
        url: `/diagram`,
        method: "DELETE",
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          created_at: queryArg.createdAt,
          updated_at: queryArg.updatedAt,
        },
      }),
    }),
    patchDiagram: build.mutation<PatchDiagramApiResponse, PatchDiagramApiArg>({
      query: (queryArg) => ({
        url: `/diagram`,
        method: "PATCH",
        body: queryArg.diagram,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          created_at: queryArg.createdAt,
          updated_at: queryArg.updatedAt,
        },
      }),
    }),
    getNode: build.query<GetNodeApiResponse, GetNodeApiArg>({
      query: (queryArg) => ({
        url: `/node`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          selected_system_id: queryArg.selectedSystemId,
          selected_subsystem_id: queryArg.selectedSubsystemId,
          xpos: queryArg.xpos,
          ypos: queryArg.ypos,
          status: queryArg.status,
          created_at: queryArg.createdAt,
          updated_at: queryArg.updatedAt,
          diagram_id: queryArg.diagramId,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
      providesTags: ["Node"],
    }),
    postNode: build.mutation<PostNodeApiResponse, PostNodeApiArg>({
      query: (queryArg) => ({
        url: `/node`,
        method: "POST",
        body: queryArg.node,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteNode: build.mutation<DeleteNodeApiResponse, DeleteNodeApiArg>({
      query: (queryArg) => ({
        url: `/node`,
        method: "DELETE",
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          selected_system_id: queryArg.selectedSystemId,
          selected_subsystem_id: queryArg.selectedSubsystemId,
          xpos: queryArg.xpos,
          ypos: queryArg.ypos,
          status: queryArg.status,
          created_at: queryArg.createdAt,
          updated_at: queryArg.updatedAt,
          diagram_id: queryArg.diagramId,
        },
      }),
    }),
    patchNode: build.mutation<PatchNodeApiResponse, PatchNodeApiArg>({
      query: (queryArg) => ({
        url: `/node`,
        method: "PATCH",
        body: queryArg.node,
        headers: { Prefer: queryArg.prefer },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          selected_system_id: queryArg.selectedSystemId,
          selected_subsystem_id: queryArg.selectedSubsystemId,
          xpos: queryArg.xpos,
          ypos: queryArg.ypos,
          status: queryArg.status,
          created_at: queryArg.createdAt,
          updated_at: queryArg.updatedAt,
          diagram_id: queryArg.diagramId,
        },
      }),
      invalidatesTags: ["Node"],
    }),
    getBoms: build.query<GetBomsApiResponse, GetBomsApiArg>({
      query: (queryArg) => ({
        url: `/boms`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          id: queryArg.id,
          received: queryArg.received,
          product_name: queryArg.productName,
          ptc_part: queryArg.ptcPart,
          branch: queryArg.branch,
          section: queryArg.section,
          material: queryArg.material,
          quantity: queryArg.quantity,
          price: queryArg.price,
          loss_adj: queryArg.lossAdj,
          material_supplier: queryArg.materialSupplier,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    getScope: build.query<GetScopeApiResponse, GetScopeApiArg>({
      query: (queryArg) => ({
        url: `/scope`,
        headers: {
          Range: queryArg.range,
          "Range-Unit": queryArg["Range-Unit"],
          Prefer: queryArg.prefer,
        },
        params: {
          id: queryArg.id,
          name: queryArg.name,
          select: queryArg.select,
          order: queryArg.order,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    postScope: build.mutation<PostScopeApiResponse, PostScopeApiArg>({
      query: (queryArg) => ({
        url: `/scope`,
        method: "POST",
        body: queryArg.scope,
        headers: { Prefer: queryArg.prefer },
        params: { select: queryArg.select },
      }),
    }),
    deleteScope: build.mutation<DeleteScopeApiResponse, DeleteScopeApiArg>({
      query: (queryArg) => ({
        url: `/scope`,
        method: "DELETE",
        headers: { Prefer: queryArg.prefer },
        params: { id: queryArg.id, name: queryArg.name },
      }),
    }),
    patchScope: build.mutation<PatchScopeApiResponse, PatchScopeApiArg>({
      query: (queryArg) => ({
        url: `/scope`,
        method: "PATCH",
        body: queryArg.scope,
        headers: { Prefer: queryArg.prefer },
        params: { id: queryArg.id, name: queryArg.name },
      }),
    }),
    postRpcSign: build.mutation<PostRpcSignApiResponse, PostRpcSignApiArg>({
      query: (queryArg) => ({
        url: `/rpc/sign`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcPgpArmorHeaders: build.mutation<
      PostRpcPgpArmorHeadersApiResponse,
      PostRpcPgpArmorHeadersApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/pgp_armor_headers`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcGenRandomUuid: build.mutation<
      PostRpcGenRandomUuidApiResponse,
      PostRpcGenRandomUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/gen_random_uuid`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcTryCastDouble: build.mutation<
      PostRpcTryCastDoubleApiResponse,
      PostRpcTryCastDoubleApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/try_cast_double`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcVerify: build.mutation<
      PostRpcVerifyApiResponse,
      PostRpcVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/verify`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcUrlDecode: build.mutation<
      PostRpcUrlDecodeApiResponse,
      PostRpcUrlDecodeApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/url_decode`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcArmor: build.mutation<PostRpcArmorApiResponse, PostRpcArmorApiArg>({
      query: (queryArg) => ({
        url: `/rpc/armor`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcPgpKeyId: build.mutation<
      PostRpcPgpKeyIdApiResponse,
      PostRpcPgpKeyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/pgp_key_id`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcAlgorithmSign: build.mutation<
      PostRpcAlgorithmSignApiResponse,
      PostRpcAlgorithmSignApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/algorithm_sign`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcLogin: build.mutation<PostRpcLoginApiResponse, PostRpcLoginApiArg>({
      query: (queryArg) => ({
        url: `/rpc/login`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcUrlEncode: build.mutation<
      PostRpcUrlEncodeApiResponse,
      PostRpcUrlEncodeApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/url_encode`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcDearmor: build.mutation<
      PostRpcDearmorApiResponse,
      PostRpcDearmorApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/dearmor`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcJwtTest: build.mutation<
      PostRpcJwtTestApiResponse,
      PostRpcJwtTestApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/jwt_test`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
    postRpcGenSalt: build.mutation<
      PostRpcGenSaltApiResponse,
      PostRpcGenSaltApiArg
    >({
      query: (queryArg) => ({
        url: `/rpc/gen_salt`,
        method: "POST",
        body: queryArg.body,
        headers: { Prefer: queryArg.prefer },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as profilerApi };
export type GetApiResponse = unknown;
export type GetApiArg = void;
export type GetMsgApiResponse = /** status 200 OK */
  | Msg[]
  | /** status 206 Partial Content */ undefined;
export type GetMsgApiArg = {
  id?: string;
  received?: string;
  statusId?: string;
  customerId?: string;
  typeId?: string;
  data?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type PostMsgApiResponse = unknown;
export type PostMsgApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** msg */
  msg: Msg;
};
export type DeleteMsgApiResponse = unknown;
export type DeleteMsgApiArg = {
  id?: string;
  received?: string;
  statusId?: string;
  customerId?: string;
  typeId?: string;
  data?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
};
export type PatchMsgApiResponse = unknown;
export type PatchMsgApiArg = {
  id?: string;
  received?: string;
  statusId?: string;
  customerId?: string;
  typeId?: string;
  data?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** msg */
  msg: Msg;
};
export type GetSys2NodeApiResponse = /** status 200 OK */
  | Sys2Node[]
  | /** status 206 Partial Content */ undefined;
export type GetSys2NodeApiArg = {
  sysId?: string;
  nodeId?: string;
  context?: string;
  dependencySysId?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type PostSys2NodeApiResponse = unknown;
export type PostSys2NodeApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** sys2node */
  sys2Node: Sys2Node;
};
export type DeleteSys2NodeApiResponse = unknown;
export type DeleteSys2NodeApiArg = {
  sysId?: string;
  nodeId?: string;
  context?: string;
  dependencySysId?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
};
export type PatchSys2NodeApiResponse = unknown;
export type PatchSys2NodeApiArg = {
  sysId?: string;
  nodeId?: string;
  context?: string;
  dependencySysId?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** sys2node */
  sys2Node: Sys2Node;
};
export type GetSys2Scope2NodeApiResponse = /** status 200 OK */
  | Sys2Scope2Node[]
  | /** status 206 Partial Content */ undefined;
export type GetSys2Scope2NodeApiArg = {
  sysId?: string;
  scopeId?: string;
  nodeId?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type PostSys2Scope2NodeApiResponse = unknown;
export type PostSys2Scope2NodeApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** sys2scope2node */
  sys2Scope2Node: Sys2Scope2Node;
};
export type DeleteSys2Scope2NodeApiResponse = unknown;
export type DeleteSys2Scope2NodeApiArg = {
  sysId?: string;
  scopeId?: string;
  nodeId?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
};
export type PatchSys2Scope2NodeApiResponse = unknown;
export type PatchSys2Scope2NodeApiArg = {
  sysId?: string;
  scopeId?: string;
  nodeId?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** sys2scope2node */
  sys2Scope2Node: Sys2Scope2Node;
};
export type GetToolsApiResponse = /** status 200 OK */
  | Tools[]
  | /** status 206 Partial Content */ undefined;
export type GetToolsApiArg = {
  id?: string;
  name?: string;
  active?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type PostToolsApiResponse = unknown;
export type PostToolsApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** tools */
  tools: Tools;
};
export type DeleteToolsApiResponse = unknown;
export type DeleteToolsApiArg = {
  id?: string;
  name?: string;
  active?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
};
export type PatchToolsApiResponse = unknown;
export type PatchToolsApiArg = {
  id?: string;
  name?: string;
  active?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** tools */
  tools: Tools;
};
export type GetSys2ScopeApiResponse = /** status 200 OK */
  | Sys2Scope[]
  | /** status 206 Partial Content */ undefined;
export type GetSys2ScopeApiArg = {
  sysId?: string;
  scopeId?: string;
  selected?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type PostSys2ScopeApiResponse = unknown;
export type PostSys2ScopeApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** sys2scope */
  sys2Scope: Sys2Scope;
};
export type DeleteSys2ScopeApiResponse = unknown;
export type DeleteSys2ScopeApiArg = {
  sysId?: string;
  scopeId?: string;
  selected?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
};
export type PatchSys2ScopeApiResponse = unknown;
export type PatchSys2ScopeApiArg = {
  sysId?: string;
  scopeId?: string;
  selected?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** sys2scope */
  sys2Scope: Sys2Scope;
};
export type GetEdgeApiResponse = /** status 200 OK */
  | Edge[]
  | /** status 206 Partial Content */ undefined;
export type GetEdgeApiArg = {
  fromId?: string;
  toId?: string;
  monitored?: string;
  status?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type PostEdgeApiResponse = unknown;
export type PostEdgeApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** edge */
  edge: Edge;
};
export type DeleteEdgeApiResponse = unknown;
export type DeleteEdgeApiArg = {
  fromId?: string;
  toId?: string;
  monitored?: string;
  status?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
};
export type PatchEdgeApiResponse = unknown;
export type PatchEdgeApiArg = {
  fromId?: string;
  toId?: string;
  monitored?: string;
  status?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** edge */
  edge: Edge;
};
export type GetSysApiResponse = /** status 200 OK */
  | Sys[]
  | /** status 206 Partial Content */ undefined;
export type GetSysApiArg = {
  id?: string;
  name?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type PostSysApiResponse = unknown;
export type PostSysApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** sys */
  sys: Sys;
};
export type DeleteSysApiResponse = unknown;
export type DeleteSysApiArg = {
  id?: string;
  name?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
};
export type PatchSysApiResponse = unknown;
export type PatchSysApiArg = {
  id?: string;
  name?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** sys */
  sys: Sys;
};
export type GetDiagramApiResponse = /** status 200 OK */
  | Diagram[]
  | /** status 206 Partial Content */ undefined;
export type GetDiagramApiArg = {
  id?: string;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type PostDiagramApiResponse = unknown;
export type PostDiagramApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** diagram */
  diagram: Diagram;
};
export type DeleteDiagramApiResponse = unknown;
export type DeleteDiagramApiArg = {
  id?: string;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
};
export type PatchDiagramApiResponse = unknown;
export type PatchDiagramApiArg = {
  id?: string;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** diagram */
  diagram: Diagram;
};
export type GetNodeApiResponse = /** status 200 OK */
  | Node[]
  | /** status 206 Partial Content */ undefined;
export type GetNodeApiArg = {
  id?: string;
  name?: string;
  selectedSystemId?: string;
  selectedSubsystemId?: string;
  xpos?: string;
  ypos?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  diagramId?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type PostNodeApiResponse = unknown;
export type PostNodeApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** node */
  node: Node;
};
export type DeleteNodeApiResponse = unknown;
export type DeleteNodeApiArg = {
  id?: string;
  name?: string;
  selectedSystemId?: string;
  selectedSubsystemId?: string;
  xpos?: string;
  ypos?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  diagramId?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
};
export type PatchNodeApiResponse = unknown;
export type PatchNodeApiArg = {
  id?: string;
  name?: string;
  selectedSystemId?: string;
  selectedSubsystemId?: string;
  xpos?: string;
  ypos?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  diagramId?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** node */
  node: Node;
};
export type GetBomsApiResponse = /** status 200 OK */
  | Boms[]
  | /** status 206 Partial Content */ undefined;
export type GetBomsApiArg = {
  id?: string;
  received?: string;
  productName?: string;
  ptcPart?: string;
  branch?: string;
  section?: string;
  material?: string;
  quantity?: string;
  price?: string;
  lossAdj?: string;
  materialSupplier?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type GetScopeApiResponse = /** status 200 OK */
  | Scope[]
  | /** status 206 Partial Content */ undefined;
export type GetScopeApiArg = {
  id?: string;
  name?: string;
  /** Filtering Columns */
  select?: string;
  /** Ordering */
  order?: string;
  /** Limiting and Pagination */
  range?: string;
  /** Limiting and Pagination */
  "Range-Unit"?: string;
  /** Limiting and Pagination */
  offset?: string;
  /** Limiting and Pagination */
  limit?: string;
  /** Preference */
  prefer?: "count=none";
};
export type PostScopeApiResponse = unknown;
export type PostScopeApiArg = {
  /** Filtering Columns */
  select?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** scope */
  scope: Scope;
};
export type DeleteScopeApiResponse = unknown;
export type DeleteScopeApiArg = {
  id?: string;
  name?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
};
export type PatchScopeApiResponse = unknown;
export type PatchScopeApiArg = {
  id?: string;
  name?: string;
  /** Preference */
  prefer?: "return=representation" | "return=minimal" | "return=none";
  /** scope */
  scope: Scope;
};
export type PostRpcSignApiResponse = unknown;
export type PostRpcSignApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    algorithm?: string;
    payload: any;
    secret: string;
  };
};
export type PostRpcPgpArmorHeadersApiResponse = unknown;
export type PostRpcPgpArmorHeadersApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    "": string;
  };
};
export type PostRpcGenRandomUuidApiResponse = unknown;
export type PostRpcGenRandomUuidApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: object;
};
export type PostRpcTryCastDoubleApiResponse = unknown;
export type PostRpcTryCastDoubleApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    inp: string;
  };
};
export type PostRpcVerifyApiResponse = unknown;
export type PostRpcVerifyApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    algorithm?: string;
    secret: string;
    token: string;
  };
};
export type PostRpcUrlDecodeApiResponse = unknown;
export type PostRpcUrlDecodeApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    data: string;
  };
};
export type PostRpcArmorApiResponse = unknown;
export type PostRpcArmorApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    "": string;
  };
};
export type PostRpcPgpKeyIdApiResponse = unknown;
export type PostRpcPgpKeyIdApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    "": string;
  };
};
export type PostRpcAlgorithmSignApiResponse = unknown;
export type PostRpcAlgorithmSignApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    algorithm: string;
    secret: string;
    signables: string;
  };
};
export type PostRpcLoginApiResponse = unknown;
export type PostRpcLoginApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    email: string;
    pass: string;
  };
};
export type PostRpcUrlEncodeApiResponse = unknown;
export type PostRpcUrlEncodeApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    data: string;
  };
};
export type PostRpcDearmorApiResponse = unknown;
export type PostRpcDearmorApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    "": string;
  };
};
export type PostRpcJwtTestApiResponse = unknown;
export type PostRpcJwtTestApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: object;
};
export type PostRpcGenSaltApiResponse = unknown;
export type PostRpcGenSaltApiArg = {
  /** Preference */
  prefer?: "params=single-object";
  body: {
    "": string;
  };
};
export type Msg = {
  id: number;
  received: string;
  status_id?: number;
  customer_id?: number;
  type_id?: number;
  data?: any;
};
export type Sys2Node = {
  sys_id: number;
  node_id: number;
  context?: string;
  dependency_sys_id?: number;
};
export type Sys2Scope2Node = {
  sys_id: number;
  scope_id: number;
  node_id: number;
};
export type Tools = {
  id: number;
  name?: string;
  active?: boolean;
};
export type Sys2Scope = {
  sys_id: number;
  scope_id: number;
  selected?: boolean;
};
export type Edge = {
  from_id: number;
  to_id: number;
  monitored?: boolean;
  status?: string;
};
export type Sys = {
  id: number;
  name?: string;
};
export type Diagram = {
  id: number;
  name?: string;
  created_at: string;
  updated_at: string;
};
export type Node = {
  id: number;
  name?: string;
  selected_system_id?: number;
  selected_subsystem_id?: number;
  xpos?: number;
  ypos?: number;
  status?: string;
  created_at: string;
  updated_at: string;
  diagram_id?: number;
};
export type Boms = {
  id?: number;
  received?: string;
  product_name?: any;
  ptc_part?: any;
  branch?: number;
  section?: string;
  material?: string;
  quantity?: number;
  price?: number;
  loss_adj?: number;
  material_supplier?: string;
};
export type Scope = {
  id: number;
  name?: string;
};
export const {
  useGetQuery,
  useGetMsgQuery,
  usePostMsgMutation,
  useDeleteMsgMutation,
  usePatchMsgMutation,
  useGetSys2NodeQuery,
  usePostSys2NodeMutation,
  useDeleteSys2NodeMutation,
  usePatchSys2NodeMutation,
  useGetSys2Scope2NodeQuery,
  usePostSys2Scope2NodeMutation,
  useDeleteSys2Scope2NodeMutation,
  usePatchSys2Scope2NodeMutation,
  useGetToolsQuery,
  usePostToolsMutation,
  useDeleteToolsMutation,
  usePatchToolsMutation,
  useGetSys2ScopeQuery,
  usePostSys2ScopeMutation,
  useDeleteSys2ScopeMutation,
  usePatchSys2ScopeMutation,
  useGetEdgeQuery,
  usePostEdgeMutation,
  useDeleteEdgeMutation,
  usePatchEdgeMutation,
  useGetSysQuery,
  usePostSysMutation,
  useDeleteSysMutation,
  usePatchSysMutation,
  useGetDiagramQuery,
  usePostDiagramMutation,
  useDeleteDiagramMutation,
  usePatchDiagramMutation,
  useGetNodeQuery,
  usePostNodeMutation,
  useDeleteNodeMutation,
  usePatchNodeMutation,
  useGetBomsQuery,
  useGetScopeQuery,
  usePostScopeMutation,
  useDeleteScopeMutation,
  usePatchScopeMutation,
  usePostRpcSignMutation,
  usePostRpcPgpArmorHeadersMutation,
  usePostRpcGenRandomUuidMutation,
  usePostRpcTryCastDoubleMutation,
  usePostRpcVerifyMutation,
  usePostRpcUrlDecodeMutation,
  usePostRpcArmorMutation,
  usePostRpcPgpKeyIdMutation,
  usePostRpcAlgorithmSignMutation,
  usePostRpcLoginMutation,
  usePostRpcUrlEncodeMutation,
  usePostRpcDearmorMutation,
  usePostRpcJwtTestMutation,
  usePostRpcGenSaltMutation,
  useLoginMutation,
  useProtectedMutation,
} = injectedRtkApi;
