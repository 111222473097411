import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import './index.css';

import App from './App'
import { store } from './app/store'

//import { worker } from './mocks/browser'
import { Provider } from 'react-redux'

// Initialize the msw worker, wait for the service worker registration to resolve, then mount
//worker.start({ quiet: true }).then(() =>

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
)

