import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Checkbox,
  Select,
  Input,
} from '@chakra-ui/react'
import { useEffect} from 'react';

function NodeDialog(props: any): JSX.Element {
    const { nodeId, nodeDataById, 
        isNodeOpen, onNodeClose, handleDone,
        scopeChoices, setScopeChoices,
        scopes,
        systemChoices, setSystemChoices,
        subsystemChoices, setSubsystemChoices,
        nodeName, setNodeName, selectedSystemId, setSelectedSystemId, selectedSubsystemId, setSelectedSubsystemId,
        nodeStatus, setNodeStatus

    } = props;
    console.log("NodeDialog nodeId="+nodeId+", \nsystemChoices="+JSON.stringify(systemChoices)+", \nsubsystemChoices="+JSON.stringify(subsystemChoices)+", \nscopeChoices="+JSON.stringify(scopeChoices)+", \nscopes="+JSON.stringify(scopes))
      
    useEffect(() =>{
        setScopeChoices(scopes)
    })

    if(nodeDataById[nodeId]) {
        return (
                <Modal isOpen={isNodeOpen} onClose={handleDone} isCentered id='modal' >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{nodeName}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {systemChoices.length > 0 &&
                                <FormControl>
                                    <label>System:</label>
                                    <RadioGroup id='systemRadioGroup' onChange={(e: any) => {
                                        var tmp: number = parseInt(e)
                                        setSelectedSystemId(tmp)
                                        setSelectedSubsystemId(0)
                                        console.log('systemRadioGroup.onChange('+tmp+'), selectedSystemId='+selectedSystemId)
                                    }} value={selectedSystemId as unknown as string}>
                                        <Stack direction='column'>
                                            <Radio key={systemChoices.length} value='0'>(None)</Radio>
                                            {systemChoices.map((s: any, i: any) => {return (<Radio key={s.id} value={s.id as string}>{s.name}</Radio>)}) }
                                            <Radio key={systemChoices.length+1} value='0'><Input size='sm' pr='4.5rem' type='text' placeholder='Other'/></Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {subsystemChoices.length > 0 &&
                                        <div>
                                            <hr/>
                                            <br/>
                                            <label>Integration:</label>
                                            <RadioGroup id='subsystemRadioGroup' onChange={(e: any) => {
                                                var tmp: number = parseInt(e)
                                                setSelectedSubsystemId(tmp)
                                                console.log('subsystemRadioGroup.onChange('+tmp+'), selectedSubsystemId='+selectedSubsystemId)
                                            }} value={selectedSubsystemId as unknown as string}>
                                                <Stack direction='row'>
                                                    <Radio key={subsystemChoices.length} value='0'>(None)</Radio>
                                                    {subsystemChoices.map((s: any, i: any) => {
                                                        if (!s.dependency || s.dependency === selectedSystemId) {
                                                        return (<Radio  key={s.id} value={s.id as string}>{s.name}</Radio>)
                                                        }
                                                    }) }
                                                </Stack>
                                            </RadioGroup>
                                        </div>
                                    }
                                    {scopeChoices.length > 0 &&
                                        <div>
                                            <hr/>
                                            <br/>
                                            <label>Scope:</label>
                                            <Stack hidden={false} spacing="-0.5" direction='column'>
                                                {scopeChoices.map((s: any, i: any) => {
                                                    return (<Checkbox key={i} size='md' colorScheme='blue'  isChecked={scopeChoices[i].selected} onChange={(e) => {
                                                        console.log("onChange handler for scope checkboxes")
                                                        var sc = scopeChoices.map((a:any) => {return a}) //important to make a copy of the obj to modify
                                                        sc[i].selected = e.target.checked
                                                        //console.log("scopeChoices="+JSON.stringify(scopeChoices)+"\nsc="+JSON.stringify(sc))
                                                        setScopeChoices(sc)
                                                        }}>{s.name}</Checkbox>)
                                                    }) }
                                            </Stack>
                                        </div>
                                    }
                                </FormControl>
                            }
                        <hr/>
                        <br/>
                        <div className='status'>
                            Status:
                            <Select value={nodeStatus} onChange={(e: any) => {
                                        console.log("nodeStatus="+nodeStatus+", e.target.value="+e.target.value)
                                        setNodeStatus(e.target.value)
                                    }}>
                                <option value='unconfigured'>Unconfigured</option>
                                <option value='todo'>Todo</option>
                                <option value='wip'>WIP</option>
                                <option value='active'>Active</option>
                            </Select>
                        </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={handleDone}>
                                Done
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
        )    
        } else {
            return <div></div> //No Node Data.
        }
}

export default NodeDialog
