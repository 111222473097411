import { Routes, Route } from 'react-router-dom'
import { Box, Center, VStack } from '@chakra-ui/react'

import { Login } from './features/auth/Login'
import { PrivateOutlet } from './utils/PrivateOutlet'
import { Cloud } from './art'

import logo from './art/logo.svg';

import './App.css';
import DSGFlow from './DSGFlow'

function LoginSuccess() {
  return (
    <Center h="500px">
      <VStack>
        <Box>Login Successful</Box>
      </VStack>
    </Center>
  )
}

function App() {

  
  return (
      <div className="App">
        
        <Box>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<PrivateOutlet />}>
              <Route index element={<LoginSuccess />} />
            </Route>
            <Route path="/profiler" element={<PrivateOutlet />}>
              <Route index element={
                <header className="App-header">
                  <div className="cloud" >
                    <Cloud />
                    <DSGFlow />
                    <img src={logo} alt="logo" width="20%" style={{"backgroundColor": "white", position:'absolute', opacity: 1}}/>  
                  </div>
                </header>
              }/>
            </Route>
          </Routes>
        </Box>

        
      </div>

  )

}

export default App
